import React, { useContext, useState } from "react";
import { graphql } from "gatsby";
import Layout from "../layouts";
import SEO from "../components/seo";
import PropTypes from "prop-types";
import Header from "../modules/header";
import VideoWithOverlay from "../elements/video-with-overlay";
import TopicCapsLabel from "../elements/caps-label/topic";
import ContentBlockLinkList from "../components/content-block-link-list";
import Button from "../elements/button";
import serializers from "../helpers/text-serializer";
import BlockContent from "@sanity/block-content-to-react";
import { VideoTranscriptModalContext } from "../context/videoTranscriptModalContext";

//TODO - is this the best query to be able to navigate between the various course subjects that make up a course?

export const query = graphql`
  query CourseSubjectTemplateQuery($id: String!) {
    courseSubject: sanityCourseSubject(id: { eq: $id }) {
      id
      title
      _createdAt
      title
      slug {
        current
      }
      excerpt
      image {
        asset {
          _id
          altText
          metadata {
            lqip
            dimensions {
              width
              height
              aspectRatio
            }
          }
        }
        hotspot {
          height
          width
          x
          y
        }
      }
      subjectSection {
        title
        intro
        _rawBody
        video {
          url {
            url
            poster
            title
          }
        }
        transcript
        file {
          type
          buttonText
          file {
            title
            file {
              asset {
                url
                title
                extension
                size
              }
            }
          }
        }
      }
    }
  }
`;

const CourseSubjectTemplate = props => {
  const { setTranscript } = useContext(VideoTranscriptModalContext);

  const { data } = props;
  const { allSubjects, index, prevSubjectSlug, nextSubjectSlug, breadcrumbs } =
    props.pageContext;

  const course = data.courseSubject;

  const files = course.subjectSection.reduce((items, item) => {
    return items.concat(item.file);
  }, []);

  return (
    <Layout className="article">
      <SEO
        title={course.title}
        excerpt={course.excerpt}
        image={course.image}
        seo={course.seo}
        taxonomy={course.taxonomy}
      />
      <Header
        title={course.title}
        image={course.image}
        breadcrumbs={breadcrumbs}
        backgroundColour={"orange"}
        text={course.excerpt}
      />
      <div className="m-article-body container">
        <div className="m-article-body-main">
          {course?.subjectSection.map((section, index) => (
            <section
              style={{
                marginBottom: "4rem",
                borderBottom: "1px solid rgba(246, 139, 31)",
                paddingBottom: "4rem",
              }}
              key={section.title}
            >
              <TopicCapsLabel text={`Section ${index + 1}`} />
              {section.title ? (
                <span className="h3">{section.title}</span>
              ) : null}
              {section.intro ? <p>{section.intro}</p> : null}
              {section.video ? (
                <>
                  <VideoWithOverlay
                    videoUrl={section.video.url.url}
                    overlayText={section.video.url.title}
                    // date={"date"}
                    image={section.video.url.poster}
                  />
                  {section.transcript ? (
                    <span
                      className="small-body"
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                      }}
                    >
                      <span
                        onClick={() => setTranscript(section.transcript)}
                        className="e-button -tertiary pointer"
                        id="course-transcript"
                      >
                        Transcript
                      </span>
                    </span>
                  ) : null}
                </>
              ) : null}
              <CourseSubjectSectionBody section={section} />
            </section>
          ))}
          <div className="">
            <h4>Continue Learning</h4>
            <div>
              {allSubjects[index - 1] ? (
                <Button
                  link={prevSubjectSlug}
                  text="Previous Topic"
                  type="primary"
                  style={{ paddingRight: "1rem" }}
                />
              ) : null}
              {allSubjects[index + 1] ? (
                <Button
                  link={nextSubjectSlug}
                  text="Next Topic"
                  type="primary"
                  style={{ paddingRight: "1rem" }}
                />
              ) : null}
            </div>
          </div>
        </div>
        {files ? (
          <div className="m-article-body-sidebar">
            <span
              style={{ position: "sticky", top: "12rem" }}
              id="course-file-download"
            >
              <ContentBlockLinkList
                title="Downloads & Links"
                links={files}
                type="background"
              />
            </span>
          </div>
        ) : null}
      </div>
    </Layout>
  );
};

CourseSubjectTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};

export default CourseSubjectTemplate;

const CourseSubjectSectionBody = ({ section }) => {
  const [open, setOpen] = useState(false);
  return (
    <div
      style={{
        maxHeight: open ? "100%" : "200px",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <BlockContent
        className="m-body-content"
        blocks={section._rawBody}
        serializers={{
          serializers,
          types: {
            block: serializers.types.block,
            undefined: serializers.undefined,
            blockVideo: serializers.blockVideo,
            blockFigure: serializers.blockFigure,
            pdfButton: serializers.pdfButton,
          },
          marks: {
            link: serializers.marks.link,
          },
        }}
        renderContainerOnSingleChild={true}
      />

      {/* //TODO: move to CSS file */}
      <div
        style={{
          position: "absolute",
          bottom: "0px",
          height: "25%",
          background: "white",
          opacity: 0.9,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          visibility: open ? "hidden" : "visible",
        }}
      >
        <span
          className="text-link pointer"
          onClick={() => setOpen(!open)}
          id="course-read-more"
        >
          Read more{" "}
          <svg
            width="15"
            height="10"
            viewBox="0 0 15 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.455078 1.23438L7.44875 8.76602L14.4424 1.23438"
              stroke="#F68B1F"
              strokeWidth="1.07595"
            />
          </svg>
        </span>
      </div>
      {open ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className="text-link pointer" onClick={() => setOpen(!open)}>
            Read less{" "}
            <svg
              width="15"
              height="10"
              viewBox="0 0 22 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 12L11 1L0.999997 12"
                stroke="#F68B1F"
                strokeWidth="1.07595"
              />
            </svg>
          </span>
        </div>
      ) : null}
    </div>
  );
};

CourseSubjectSectionBody.propTypes = {
  section: PropTypes.object,
};
