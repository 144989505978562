import React from "react";
import PropTypes from "prop-types";
import CapsLabel from "../elements/caps-label/";
import Link, { LinkPropTypes } from "../elements/link";
import linkHelper from "../helpers/link";

const ContentBlockLinkList = ({ title, links, type = "border" }) => {
  return (
    links?.[0] && (
      <div className={`c-content-block-link-list -${type}`}>
        {title && <CapsLabel text={title} />}
        <ul>
          {links.map((link, i) => (
            <li key={i}>
              <Link {...linkHelper(link)} />
            </li>
          ))}
        </ul>
      </div>
    )
  );
};

export default ContentBlockLinkList;

// Todo: Add Link object shape to propTypes

export const ContentBlockLinkListPropTypes = {
  title: PropTypes.string,
  type: PropTypes.oneOf(["border", "background"]),
  links: PropTypes.arrayOf(PropTypes.shape(LinkPropTypes)),
};

ContentBlockLinkList.propTypes = ContentBlockLinkListPropTypes;
